<template>
	<div>
		<template v-if="!loggedIn">
			{{ $t("collaboration.please_setup_an_account_message") }}
		</template>
		<template v-if="inProgress">
			<p>{{ $t("misc.please_wait") }}</p>
			<v-progress-linear
				color="green"
				height="18px"
				indeterminate
			></v-progress-linear>
		</template>
		<template v-if="doc">
			<span v-if="doc.status == 'activated'">
				<p>{{ $t("collaboration.activated") }}</p>
				<v-btn color="primary" router-link to="/home">{{
					$t("nav.start_assessment")
				}}</v-btn>
			</span>
			<span v-if="doc.status == 'revoked'">
				{{ $t("collaboration.revoked") }}
			</span>
		</template>

		<template v-if="error">
			<v-alert color="green" text>
				<p v-if="isLead">
					{{ $t("collaboration.dont_delegate_to_yourself") }}
				</p>
				<p v-if="errorMessage">{{ errorMessage }}</p>
			</v-alert>
		</template>
	</div>
</template>
<script>
import firebase from "firebase";
// Retrieves and activates a collaboration
// @group Delegation
import { arrayUnion } from 'vuex-easy-firestore'

export default {
	name: "Referral",
	props: {
		// the ID of the collaboration
		code: { type: String },
	},
	data: () => {
		return {
			error: false,
			inProgress: false,
			errorMessage: false,
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		loggedIn() {
			return this.$store.state.auth.logged_in || false;
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		doc() {
			return this.$store.state.delegation.data;
		},
	},
	watch: {
		doc: {
			immediate: true,
			handler(doc) {
				if (!doc) {
					return;
				}
				if ((doc.status == "published") & !this.isLead) {
					this.start();
				}
			},
		},
		loggedIn: {
			immediate: true,
			handler() {
				if (this.loggedIn) {
					if (this.code) {
						this.$store.dispatch("delegation/openDBChannel", {
							pathVariables: { id: this.code },
						});
					}
				}
			},
		},
		isLead: {
			immediate: true,
			handler(value) {
				if (value) {
					this.error = true;
				}
			},
		},
	},
	methods: {
		async activate() {
			var functions = firebase.app().functions("europe-west1");
			var activateCollaboration = functions.httpsCallable(
				"activateCollaboration"
			);
			var collaboration = this.doc;
			collaboration.id = this.code;
			return await activateCollaboration({
				user: this.auth.id,
				collaboration: collaboration,
			});
		},
		start() {
			const self = this;
			self.inProgress = true;
			self
				.activate()
				.then(() => {
					self.$store.dispatch("auth/patch",  {organisations: arrayUnion(self.doc.organisation)});
					self.$router.replace("/home");
				})
				.catch((e) => {
					this.errorMessage = e;
				});
		},
	},
};
</script>
